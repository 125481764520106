//大屏配置管理

const visuals = {
  "Default": {
    title: "智慧河道水雨情在线监测管理平台",  // 对外展示名称
    dpName: "Default",  // 内部标识
    mapdata: {   // 地图信息
      center: { // 默认经纬度
        lng: 114.54042,
        lat: 22.741633,
      },
      zoom: 13  // 缩放级别
    },
    picName: "visual_default.png",

  },
  "RPWS": {
    title: "深圳市智慧道路积水预警救援系统",  // 对外展示名称
    dpName: "RPWS",    // 内部标识
    mapdata: {        // 地图信息
      center: {     // 默认经纬度
        lng: 113.931809,
        lat: 22.579825,
      },
      zoom: 13  // 缩放级别
    },
    picName: "visual_rpws.png",
  }
}

//获取大屏配置
const getVisualConfig = (dpName) => {
  let ackConfig = {}
  if (dpName) {
    ackConfig = visuals[dpName] != undefined ? visuals[dpName] : visuals.default;
  }

  return ackConfig
}

//获取大屏配置
const getAllVisualConfig = () => {
  return visuals
}

export default {
  getVisualConfig,
  getAllVisualConfig,
}